export const getUserInfo = () => useNuxtApp().$request<Api.User.UserInfo>(`/user/info`, { headers: { test: '123' } })
export const logout = () => useNuxtApp().$request('/user/logout')

interface LoginRequest {
  type: number
  phone: string
  code: string
  promotionCode?: string
}
export const login = (params: LoginRequest) => useNuxtApp().$request<Api.User.UserInfo>('/user/phone/login', { method: 'POST', body: params, baseURL: '/api/v2' })

export const getSmsCode = (phone: string) => useNuxtApp().$request('/user/phone/sendSms', { query: { phone } })
export const getQrCode = () => useNuxtApp().$request<Api.User.UserWxInfo>('/user/wx/official/qrCode')
export const getOfficialStatus = (ticket: string) => useNuxtApp().$request<Api.User.UserInfo>('/user/wx/official/check', { query: { ticket }, baseURL: '/api/v2' })

export function useArticleList(query: MaybeRef<{ index: number, limit: number }>) {
  return useRequest<Api.TikTokNews.ArticlesSearchResponse>('/content/articles?status=1', {
    baseURL: useRuntimeConfig().public.apiCmsV1,
    params: query,
    transform(res) {
      return { data: res.data?.map(article => ({ ...article, content: '' })), pagination: res.pagination }
    },
  })
}

export function useGetArticleDetail(id: number) {
  return useRequest<Api.TikTokNews.Article>(`/content/articles/${id}`, {
    baseURL: useRuntimeConfig().public.apiCmsV1,
  })
}
